'use strict';

var modalCreateList = require('./modalCreateList');
var modalEditList = require('./modalEditList');
var modalDeleteList = require('./modalDeleteList');
var cardList = require('./cardList');
var errorDisplay = require('./errorDisplay');
var scrollAnimate = require('base/components/scrollAnimate');

var updatePaginationPage = function (data) {
    $.spinner().start();
    $.ajax({
        url: data.url,
        method: 'POST',
        dataType: 'html',
        data: {
            paginationPage: data.paginationPage? data.paginationPage : 1
        },
        success: function (html) {
            var $html = $(html);

            $('.product-list-enhancements-global-error-display').trigger('clear');
            $('.product-list-enhancements-landing').trigger('updateLists', {
                $lists: $html.find('.lists'),
                $listsPagination: $html.find('.product-list-enhancements-landing-pagination')
            });
        },
        error: function () {
            $.spinner().stop();
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });
};

var updateModalCreateListNames = function () {
    var listNames = $('.product-list-enhancements-landing .lists').data('listNames');
    var listNamesArray = [];

    if (listNames) {
        listNamesArray = listNames.split('|')
    }

    $('#wishlistEnhancementsModalCreateList, #wishlistEnhancementsModalEditList').data('listNames', listNamesArray);
};

var eventClickPaginationPage = function (e) {
    e.preventDefault();
    var $this = $(this);

    updatePaginationPage({
        url: $this.parents('.product-list-enhancements-landing-pagination').data().url,
        paginationPage: $this.data().paginationPage
    });
};

var eventUpdateLists = function (e, data) {
    var $landing = $('.product-list-enhancements-landing');
    var $pageinationSelectedButton = data.$listsPagination.find('.pagination-page[disabled]');

    $.spinner().start();
    cardList.destroy();
    $landing
        .find('.lists')
        .replaceWith(data.$lists);
    $landing
        .find('.product-list-enhancements-landing-pagination')
        .replaceWith(data.$listsPagination);

    if (data.$lists.data('listNames') === '') {
        $landing.find('.lists').addClass('d-none');
        $landing.find('.product-list-enhancements-message-empty').removeClass('d-none');
    } else {
        $landing.find('.lists').removeClass('d-none');
        $landing.find('.product-list-enhancements-message-empty').addClass('d-none');
    }

    if (data.$listsPagination.data().groupsCount === 1) {
        $landing.find('.product-list-enhancements-landing-pagination').addClass('d-none');
    } else {
        $landing.find('.product-list-enhancements-landing-pagination').removeClass('d-none');
    }

    if (
        $pageinationSelectedButton.length
        && $pageinationSelectedButton.data().url !== window.location.href
    ) {
        window.history.pushState(
            $pageinationSelectedButton.data().paginationPage,
            '',
            $pageinationSelectedButton.data().url
        );
    }

    cardList.init();
    updateModalCreateListNames();
    $.spinner().stop();
};

var eventOpenEditList = function (e, data) {
    $('#wishlistEnhancementsModalEditList')
        .attr({
            'data-product-list-id': data.productListId,
            'data-product-list-name': data.productListName
        })
        .data({
            productListId: data.productListId,
            productListName: data.productListName
        })
        .modal('show');
};

var eventOpenDeleteList = function (e, productListId) {
    $('#wishlistEnhancementsModalDeleteList')
        .attr('data-product-list-id', productListId)
        .data('productListId', productListId)
        .modal('show');
};

var eventClickToggle = function (e) {
    $('.list-container .notes').toggleClass('show');
    $('.product-list-enhancements-landing-notes-text-show, .product-list-enhancements-landing-notes-text-hide').toggleClass('d-none');
};

var eventPopstateWindow = function () {
    var urlSearchParams = new URLSearchParams(window.location.search);
    updatePaginationPage({
        url: $('.product-list-enhancements-landing .product-list-enhancements-landing-pagination').data().url,
        paginationPage: urlSearchParams.get('paginationPage')
    });
};

/**
 * replaces the content in the popover for the selected product.
 */
function fillPopoverElement (url, popover) {
    var $popover = $('#' + popover);
    $.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            $popover.find('.popover-body').html(data.renderedTemplate);
            $popover.popover('update');
        }
    });
}

var showSearchForm = function (e) {
    e.preventDefault();
    var showSearchFormUrl = $(this).data('url');
    var popover = $(this).attr('aria-describedby');
    fillPopoverElement(showSearchFormUrl, popover);
};

var viewOthersLists = function () {
    window.location = $(this).data('url');
}

var noSearchResults = function (e) {
    e.preventDefault();
    location.reload();
}

var moreSearchResults = function (e) {
    e.preventDefault();
    $.ajax({
        url: $('.response').data('url'),
        method: 'get',
        data: {
            pageNumber: parseInt($('.response').data('page-number'), 10) + 1,
            pageSize: $('.response').data('page-size'),
            email: $('.response').data('email')
        }
    }).done(function (data) {
        $('.more-search-results').remove();
        $('.response').data('page-number', data.pageNumber);

        if (Object.prototype.hasOwnProperty.call(data.results, 'hits')) {
            data.results.hits.forEach(function (hit) {
                var $resultsDiv = $('.wishlist-result-template').clone();
                $resultsDiv.removeClass('wishlist-result-template');
                $resultsDiv.find('.card-header h3').text(hit.name);
                $resultsDiv.find('.view-wishlist').data('url', hit.url);
                $('.response .wishlist-search-results').append($resultsDiv);
                $resultsDiv.removeClass('d-none');
            });

            if (data.moreResults) {
                var $moreButton = $('.more-btn-template').clone();
                $moreButton.removeClass('more-btn-template');
                $moreButton.addClass('more-search-results');
                $moreButton.removeClass('d-none');
                $('.response .wishlist-search-results').append($moreButton);
            }
        }
    }).fail(function () {
        $('.more-search-results').remove();
    });
}

var toggleLists = function (e) {
    e.preventDefault();
    $('.list-container').removeClass('d-none');
    !$('.response').hasClass('d-none') ? $('.response').addClass('d-none') : '';
}

var addEventListeners = function () {
    $('.product-list-enhancements-landing')
        .on('click', '.pagination-page', eventClickPaginationPage)
        .on('openDeleteList', eventOpenDeleteList)
        .on('openEditList', eventOpenEditList)
        .on('updateLists', eventUpdateLists)
        .on('click', '.product-list-enhancements-landing-notes-toggle', eventClickToggle)
        .on('click', '.product-list-enhancements-search-list-btn', showSearchForm)
        .on('click', '.no-results-div a', noSearchResults)
        .on('click', '.more-search-results', moreSearchResults)
        .on('click', '.toggleLists', toggleLists);
    $('.response')
        .on('click', '.view-wishlist', viewOthersLists)
        .on('click', '.toggleLists', toggleLists);
    $(window)
        .on('popstate', eventPopstateWindow);
};

var removeEventListeners = function () {
    $('.product-list-enhancements-landing')
        .off('click', '.pagination-page', eventClickPaginationPage)
        .off('openDeleteList', eventOpenDeleteList)
        .off('openEditList', eventOpenEditList)
        .off('updateLists', eventUpdateLists)
        .off('click', '.product-list-enhancements-landing-notes-toggle', eventClickToggle)
        .off('click', '.product-list-enhancements-search-list-btn', showSearchForm)
        .off('click', '.search-wishlists', searchWishlists)
        .off('click', '.no-results-div a', noSearchResults)
        .off('click', '.more-search-results', moreSearchResults)
        .off('click', '.toggleLists', toggleLists);
    $('.response')
        .off('click', '.view-wishlist', viewOthersLists)
        .off('click', '.toggleLists', toggleLists);
    $(window)
        .off('popstate', eventPopstateWindow);
};

var searchWishlists = function (e) {
    $('body').on('click', '.search-wishlists', function (e) {
        e.preventDefault();

        $('.search-for-lists').find('.is-invalid').removeClass('is-invalid');

        if (!$('.search-email').val()) {
            $('.search-email').addClass('is-invalid').siblings('.invalid-feedback').html($('.search-email').data('missing-msg'));
            return false;
        }

        $('.product-list-enhancements-landing .list-container').spinner().start();
        var url = $(this).data('url');
        $('.wishlist-search-results-count span').addClass('d-none');
        $('.no-results-msg').addClass('d-none');
        $('.no-results-div a').addClass('d-none');

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: $('.search-for-lists').serialize(),
            success: function (data) {
                $('.product-list-enhancements-landing .list-container').addClass('d-none');
                $('.wishlist-search-results .card').remove();
                $('.wishlist-search-results .more-managed-lists').remove();
                $('.response').removeClass('d-none');
                if (Object.prototype.hasOwnProperty.call(data.results, 'hits')) {
                    data.results.hits.forEach(function (hit) {
                        var $resultsDiv = $('.wishlist-result-template').clone();
                        $resultsDiv.removeClass('wishlist-result-template');
                        $resultsDiv.find('.card-header h3').text(hit.name ? hit.name : 'Wishlist');
                        if (hit.count === 1) {
                            $resultsDiv.find('.item').removeClass('d-none').find('.item-count').text(hit.count ? hit.count : '');
                        } else {
                            $resultsDiv.find('.items').removeClass('d-none').find('.items-count').text(hit.count ? hit.count : '0');
                        }
                        $resultsDiv.find('.view-wishlist').attr('data-url', hit.url);
                        $('.response .wishlist-search-results').append($resultsDiv);
                        $resultsDiv.removeClass('d-none');
                    });

                    if (data.moreResults) {
                        var $moreButton = $('.more-btn-template').clone();
                        $moreButton.removeClass('more-btn-template');
                        $moreButton.addClass('more-search-results');
                        $moreButton.removeClass('d-none');
                        $('.response .wishlist-search-results').append($moreButton);
                    }
                    $('.wishlist-search-results-number').text(data.results.total);

                    if (data.results.hits.length === 0) {
                        $('.no-results-msg').removeClass('d-none');
                    }
                }

                $('.no-results-div a').removeClass('d-none');
                scrollAnimate();

                if (data.results.hits && data.results.hits.length === 1) {
                    $('.wishlist-search-results-count span.wishlist-search-result-text').removeClass('d-none');
                } else if (data.results.hits && data.results.hits.length > 1) {
                    $('.wishlist-search-results-count span.wishlist-search-results-number').removeClass('d-none');
                    $('.wishlist-search-results-count span.wishlist-search-results-text').removeClass('d-none');
                }

                $('.response').data('email', $('#searchWishlistEmail').val());
                $('.response').data('total', data.results.total);

                $('.product-list-enhancements-landing .list-container').spinner().stop();
                $('[data-toggle="popover"].interactive').popover('hide');

            },
            error: function () {
                $('.product-list-enhancements-landing .list-container').spinner().stop();
            }
        });
        return true;
    });
}

var init = function () {
    if ($('.product-list-enhancements-landing').length) {
        addEventListeners();
        updateModalCreateListNames();
        modalCreateList.init();
        modalDeleteList.init();
        modalEditList.init();
        cardList.init();
        errorDisplay.init();
        searchWishlists();
    }
}

var destroy = function () {
    removeEventListeners();
    modalCreateList.destroy();
    modalDeleteList.destroy();
    modalEditList.destroy();
    cardList.destroy();
    errorDisplay.destroy();
}

module.exports = {
    init,
    destroy
}
