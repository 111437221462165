'use strict';

var productListEnhancementsHelpers = require('./helpers.js');
var cart = require('../cart/cart.js');
var base = require('../product/base');

var getPid = function() {
    return $('#wishlistModalShowLists').data().pid;
};

var getListItemIds = function() {
    return $('#wishlistModalShowLists').data().listItemIds;
};

var getListId = function() {
    return $('#wishlistModalShowLists').data().listId;
};

var getListAction = function() {
    var $modal = $('#wishlistModalShowLists');
    return (!$modal.data().action? 'add' : $modal.data().action);
};

var getQuantity = function() {
    if ($('#wishlistModalShowLists').data().quantity) {
        return $('#wishlistModalShowLists').data().quantity;
    } else {
        return 1;
    }
};

var updateProductListProducts = function($html) {
    // if we have a new DOM replace the old DOM
    if ($html) {
        $('#wishlistModalShowLists')
            .find('.product-list-enhancements-modal-show-lists-toggle-lists')
            .replaceWith($html);
    // if we don't have a new DOM then just empty it out
    } else {
        $('#wishlistModalShowLists')
            .find('.product-list-enhancements-modal-show-lists-toggle-lists')
            .empty();
    }
};

var updateModalListDataState = function() {
    var $modal = $('#wishlistModalShowLists');
    var modalListDataState = $modal.data().modalListDataState;
    var selectedLists = $modal.find('.toggle.selected');
    var selectedListIds = [];

    var getListDiff = function(list1, list2) {
        var i;
        var misses = [];

        for (i = 0; i < list1.length; i++) {
            var ii;
            var found = false;
            for (ii = 0; ii < list2.length; ii++) {
                if (
                    list2[ii].productListId === list1[i].productListId
                ) {
                    found = true
                }
            }
            if (!found) {
                misses.push(list1[i]);
            }
        }

        return misses;
    };

    //get all the lists that are selected
    selectedLists.each(function() {
        selectedListIds.push({
            pid: getPid(),
            productListId: $(this).data('productListId'),
            productListName: $(this).data('productListName'),
            productListQuantities: $(this).data('productListPidQuantity'),
            productListUrl: $(this).data('productListUrl'),
        });
    });

    //save off the initial set of selected lists so we know what we started with
    if (!modalListDataState.listsInitial) {
        modalListDataState.listsInitial = selectedListIds;
    }

    //save the lists as it after a change happened or if this is the initial load
    modalListDataState.listsCurrent = selectedListIds;

    //figure if any lists have been removed
    modalListDataState.removed = getListDiff(modalListDataState.listsInitial, modalListDataState.listsCurrent);

    //figure if any lists have been added
    modalListDataState.added = getListDiff(modalListDataState.listsCurrent, modalListDataState.listsInitial);

    //look to see if anything changed then set the isChange flag
    if (modalListDataState.added.length || modalListDataState.removed.length) {
        modalListDataState.isChanged = true;
    } else {
        modalListDataState.isChanged = false;
    }

    if (modalListDataState.added.length && modalListDataState.removed.length) {
        modalListDataState.productListActionType = 'both';
    } else if (modalListDataState.added.length) {
        modalListDataState.productListActionType = 'add';
    } else if (modalListDataState.removed) {
        modalListDataState.productListActionType = 'remove';
    } else {
        modalListDataState.productListActionType = 'none';
    }

    modalListDataState.toast = {}
    if (modalListDataState.productListActionType === 'remove' && modalListDataState.removed.length === 1) {
        // only removed 1
        modalListDataState.toast = modalListDataState.removed[0];
        modalListDataState.toast.productListActionType = modalListDataState.productListActionType;
    } else if (modalListDataState.productListActionType === 'remove' && modalListDataState.removed.length > 1) {
        // removed several
        modalListDataState.toast.productListId = [];
        modalListDataState.toast.productListQuantities = [];
        modalListDataState.toast.pid = getPid();

        var i;
        for (i = 0; i < modalListDataState.removed.length; i++) {
            modalListDataState.toast.productListId.push(modalListDataState.removed[i].productListId);
            modalListDataState.toast.productListQuantities.push(modalListDataState.removed[i].productListQuantities);
        }

        modalListDataState.toast.productListActionType = modalListDataState.productListActionType;
    } else if (modalListDataState.productListActionType === 'add' && modalListDataState.added.length === 1) {
        // only added 1
        modalListDataState.toast = modalListDataState.added[0];
        modalListDataState.toast.productListActionType = modalListDataState.productListActionType;
    } else if (modalListDataState.productListActionType === 'add' && modalListDataState.added.length > 1) {
        // added several
        modalListDataState.toast.productListActionType = 'both';
    } else if (modalListDataState.productListActionType === 'both') {
        // multiple adds or multiple removals
        modalListDataState.toast.productListActionType = modalListDataState.productListActionType;
    } else {
        // no changes
        modalListDataState.toast.productListActionType = modalListDataState.productListActionType;
    }

    $modal.data().modalListDataState = modalListDataState;
};

var switchListViewsRegistered = function() {
    $('#wishlistModalShowLists')
        .find('.product-list-enhancements-modal-show-lists-toggle-lists, .product-list-enhancements-modal-show-lists-toggle-list-form')
        .toggleClass('d-none');
    $('#wishlistModalShowLists')
        .find('.product-list-enhancements-modal-show-lists-header-close, .product-list-enhancements-modal-show-lists-header-back')
        .toggleClass('show');
};

var eventShow = function(e) {
    var $this = $(this);
    var action = getListAction();
    var dataParams = {};
    $this.data('modalListDataState', {});
    productListEnhancementsHelpers.setModalPositionTop($this);
    $('.product-list-enhancements-toast').trigger('dismiss');
    if (action === 'move') {
        $('.product-list-enhancements-modal-show-lists-add').hide();
        dataParams = {
            listIdOriginal: $this.data().listId,
            listItemIds: JSON.stringify(getListItemIds()),
            action: action
        };
    } else {
        $('.product-list-enhancements-modal-show-lists-move').hide();
        dataParams = {
            pid: getPid(),
            action: action
        };
    }
    $.ajax({
        url: $this.find('.modal-body').data().url,
        type: 'POST',
        dataType: 'html',
        data: dataParams,
        success: function(html) {
            $('.product-list-enhancements-global-error-display').trigger('clear');
            updateProductListProducts($(html));
            updateModalListDataState();
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });
    addListEventListenersWindow();
};

var eventHidden = function(e) {
    removeListEventListenersWindow();
    updateProductListProducts();
    productListEnhancementsHelpers.openToast(
        $('#wishlistModalShowLists').data().modalListDataState.toast
    );
};

var eventStopPropagation = function(e) {
    e.stopPropagation();
};

var eventClickWindow = function(e) {
    $('#wishlistModalShowLists').modal('toggle');
};

var eventResizeWindow = function() {
    productListEnhancementsHelpers.setModalPositionTop($('#wishlistModalShowLists'));
};

var eventKeyupName = function(e) {
    var $this = $(this);
    var $invalidFeedback = $this.next('.invalid-feedback');
    var $submitButton = $('.product-list-enhancements-create-list-form .create');
    var namesExisting = $('#wishlistModalShowLists [data-list-names]').data().listNames.split('|');
    var nameNew = $this.val().replace(/[^A-Za-z0-9]/g, '').toLowerCase();
    if (nameNew !== '' && namesExisting.indexOf(nameNew) !== -1) {
        $submitButton.attr('disabled', 'disabled');
        $invalidFeedback.text($this.data().errorDuplicateName).addClass('d-block');
        $this.addClass('is-invalid');
    } else {
        $submitButton.removeAttr('disabled');
        $invalidFeedback.text('').removeClass('d-block');
        $this.removeClass('is-invalid');
    }
};

var eventToggleProductOnProductList = function(e) {
    e.preventDefault();
    var $listButton = $(this);
    var $productContainer = $('.product-detail[data-pid='+ $listButton.data().pid +']');
    var options = base.methods.getOptions($productContainer);
    options ? $listButton.attr('data-product-options', JSON.stringify(options)) : '';

    $.spinner().start();
    $.ajax({
        url: $listButton.data().url,
        method: 'POST',
        dataType: 'html',
        data: {
            pid: getPid(),
            productListId: $listButton.data().productListId,
            quantity: getQuantity(),
            options: options
        },
        success: function(html) {
            var $html = $(html);
            var $modal = $('#wishlistModalShowLists');
            var productListSelectedCount = $html.find('.selected').length;
            $('.product-list-enhancements-global-error-display').trigger('clear');
            updateProductListProducts($html);
            updateModalListDataState();
            productListEnhancementsHelpers.updateUncachedData({
                actionType: productListSelectedCount ? 'add' : 'remove',
                pid: getPid()
            });
            productListEnhancementsHelpers.updateLinkData();

            if (!$modal.data().multiSelect) {
                $modal
                    .one('hidden.bs.modal', function () {
                        productListEnhancementsHelpers.openToast($listButton.data());
                    })
                    .modal('hide');
            }
            if ($modal.data().removeFromCart && $listButton.data().productListActionType === 'add') {
                var urlParams = {
                    pid: getPid(),
                    uuid: $modal.data().cartUUID
                };

                cart.removeProductFromCart({
                    url: cart.appendToUrl($modal.data().urlRemoveFromCart, urlParams),
                    uuid: urlParams.uuid,
                    pid: getPid()
                });
            }

            $.spinner().stop();
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
};

var eventMoveProductsToProductList = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistModalShowLists');
    var $listButton = $(this);
    $.spinner().start();
    $.ajax({
        url: $listButton.data().url,
        method: 'POST',
        dataType: 'html',
        data: {
            listItemIds: JSON.stringify($modal.data().listItemIds),
            productListIdOriginal: getListId(),
            productListIdNew: $listButton.data().productListId,
            keepInOriginalList: $modal.find('.keep').is(':checked')
        },
        success: function(html) {
            $('.product-list-enhancements-global-error-display').trigger('clear');
            $('.product-list-enhancements-detail').trigger('updateProducts', $(html).find('.product-list-enhancements-detail-products'));
            if (!$modal.data().multiSelect) {
                $modal.modal('hide');
            }
            $.spinner().stop();
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
}

var eventToggleNewProductListForm = function(e) {
    e.preventDefault();
    switchListViewsRegistered();
    $('#wishlistModalShowLists #newProductListName')
        .val('')
        .removeClass('is-invalid')
        .next('.invalid-feedback')
        .text('')
        .removeClass('d-block');
    $('.product-list-enhancements-create-list-form .create')
        .removeAttr('disabled');
};

var eventSubmitNewProductListForm = function(e) {
    e.preventDefault();

    var $newProductListName = $('#newProductListName');
    if ($newProductListName.val() && $newProductListName.val().length > 1) {
        $newProductListName.removeClass('is-invalid');
        $.spinner().start();
        $.ajax({
            url: $(e.target).data().url,
            method: 'POST',
            dataType: 'html',
            data: {
                name: $newProductListName.val(),
                pid: getPid(),
                view: 'modalShowLists',
                listIdOriginal: getListId(),
                action: getListAction()
            },
            success: function(html) {
                var $html = $(html);
                $('.product-list-enhancements-global-error-display').trigger('clear');
                updateProductListProducts($html);
                updateModalListDataState();
                $.spinner().stop();
            },
            error: function() {
                $('.product-list-enhancements-global-error-display').trigger('error');
                $.spinner().stop();
            }
        });
        switchListViewsRegistered();
        $newProductListName.val('');
    } else {
        $newProductListName.addClass('is-invalid');
    }
};

var eventClickRemove = function(e) {
    e.preventDefault();
    $.spinner().start();
    var $this = e.currentTarget;
    var pid = $this.dataset.pid

    $.ajax({
        url: $this.dataset.url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: pid
        },
        success: function(data) {
            if (data.success) {
                $('.product-list-enhancements-global-error-display').trigger('clear');
                productListEnhancementsHelpers.updateUncachedData({
                    actionType: 'remove',
                    pid: pid
                });
                productListEnhancementsHelpers.updateLinkData();
                $('.product-list-enhancements-modal-show-lists').modal('hide');

                productListEnhancementsHelpers.openToast({
                    pid: pid,
                    productListId: data.productListIds,
                    productListQuantities: data.productListsQuantities,
                    productListName: data.productListName,
                    productListUrl: data.productListUrl,
                    productListActionType: 'remove'
                });

            } else {
                $('.product-list-enhancements-global-error-display').trigger('error');
            }
            $.spinner().stop();
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $('.product-list-enhancements-modal-show-lists').modal('hide');
            $.spinner().stop();
        }
    });
};

var addListEventListenersWindow = function() {
    $(window)
        .on('resize scroll', eventResizeWindow)
        .on('click', eventClickWindow);
};

var removeListEventListenersWindow = function() {
    $(window)
        .off('resize scroll', eventResizeWindow)
        .off('click', eventClickWindow);
};

var addListEventListeners = function() {
    $('#wishlistModalShowLists')
        .on('click', eventStopPropagation)
        .on('show.bs.modal', eventShow)
        .on('hidden.bs.modal', eventHidden)
        .on('keyup', '#newProductListName', eventKeyupName)
        .on('click', '.product-list-enhancements-modal-show-lists-header .product-list-enhancements-modal-show-lists-header-back', eventToggleNewProductListForm)
        .on('click', '.product-list-enhancements-create-list-btn, .product-list-enhancements-modal-show-lists-toggle-list-form .cancel', eventToggleNewProductListForm)
        .on('click', '.toggle', eventToggleProductOnProductList)
        .on('click', '.move', eventMoveProductsToProductList)
        .on('submit', '.product-list-enhancements-modal-show-lists-toggle-list-form form', eventSubmitNewProductListForm)
        .on('click', '.remove-from-all', eventClickRemove);
};

var removeListEventListeners = function() {
    $('#wishlistModalShowLists')
        .off('click', eventStopPropagation)
        .off('show.bs.modal', eventShow)
        .off('hidden.bs.modal', eventHidden)
        .off('keyup', '#newProductListName', eventKeyupName)
        .off('click', '.product-list-enhancements-modal-show-lists-header .product-list-enhancements-modal-show-lists-header-back', eventToggleNewProductListForm)
        .off('click', '.product-list-enhancements-create-list-btn, .product-list-enhancements-modal-show-lists-toggle-list-form .cancel', eventToggleNewProductListForm)
        .off('click', '.toggle', eventToggleProductOnProductList)
        .off('click', '.move', eventMoveProductsToProductList)
        .off('submit', '.product-list-enhancements-modal-show-lists-toggle-list-form form', eventSubmitNewProductListForm)
        .off('click', '.remove-from-all', eventClickRemove);
};

var init = function() {
    addListEventListeners();
    $('#wishlistModalShowLists')
        .data('modalListDataState', {});
};

var destroy = function() {
    $('#wishlistModalShowLists')
        .data('pid', false)
        .data('modalListDataState', {});
    updateProductListProducts();
    removeListEventListeners();
    removeListEventListenersWindow();
};

module.exports = {
    init,
    destroy
};
