'use strict';

var modalShareList = require('./modalShareList');
var modalDeleteProducts = require('./modalDeleteProducts');
var overlayDetailSelected = require('./overlayDetailSelected');
var cardProduct = require('./cardProduct');
var productBase = require('../product/base');
var modalQuickView = require('./modalQuickView');
var detailNotes = require('./detailNotes');
var errorDisplay = require('./errorDisplay');

var unsetSelectedProductsState = function () {
    $('.product-list-enhancements-detail-nav').addClass('open');
    $('.product-list-enhancements-detail-deselect').removeClass('show').addClass('d-none');
    $('.public-toggle').removeClass('d-none').addClass('show');
    $('#wishlistOverlayDetailSelected').trigger('close');
    $('.product-list-enhancements-detail')
        .find('.product-list-enhancements-card-product')
        .trigger('unselect');
};

var toggleSelectedProductsState = function () {
    if ($('.product-list-enhancements-card-product[data-selected="true"]').length) {
        $('.product-list-enhancements-detail-nav').removeClass('open');
        $('.product-list-enhancements-detail-deselect').addClass('show').removeClass('d-none');
        $('.public-toggle').removeClass('show').addClass('d-none');
        if (
            $('.product-list-enhancements-card-product.product-type-master[data-selected="true"]').length
            || $('.product-list-enhancements-card-product.unavailable[data-selected="true"]').length
        ) {
            $('.product-list-enhancements-overlay-detail-selected-add').attr('disabled', true);
        } else {
            $('.product-list-enhancements-overlay-detail-selected-add').attr('disabled', false);
        }
        $('#wishlistOverlayDetailSelected').trigger('open');
    } else {
        unsetSelectedProductsState();
    }
};

var eventKeyPressEscapeWindow = function (e) {
    if (e.key && e.key === 'Escape') {
        unsetSelectedProductsState();
    }
};

var eventUnselectAllProducts = function (e) {
    unsetSelectedProductsState();
};

var eventUpdateSelectedProducts = function () {
    toggleSelectedProductsState();
};


var eventUpdateProducts = function (e, $products) {
    $.spinner().start();
    unsetSelectedProductsState();
    cardProduct.destroy();
    $('.product-list-enhancements-detail')
        .find('.product-list-enhancements-detail-products')
        .replaceWith($products);
    cardProduct.init();
    $.spinner().stop();
};

var eventClickSelectAll = function (e) {
    e.preventDefault();
    $('.product-list-enhancements-detail')
        .find('.product-list-enhancements-card-product')
        .trigger('select');
    toggleSelectedProductsState();
};

var eventClickDeselect = function (e) {
    e.preventDefault();
    unsetSelectedProductsState();
};


var eventClickShare = function (e) {
    e.preventDefault();
};

var eventClickAddToCart = function (e) {
    e.preventDefault();
    var $this = $(this);
    var $products = $this.parents('.product-list-enhancements-detail').find('.product-list-enhancements-card-product:not(.unavailable):not(.product-type-master)');
    var addToCartUrl = $this.data().url;
    var pidsObj = [];
    $products.each(function () {
        var $product = $(this);
        pidsObj.push({
            pid: $product.data().pid,
            qty: $product.find('.product-list-enhancements-card-product-quantity').val(),
            options: ''
        });
    });
    if (pidsObj.length) {
        $.spinner().start();
        pidsObj = JSON.stringify(pidsObj);
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: {
                pidsObj
            },
            success: function (data) {
                if (data.error === false) {
                    $('.product-list-enhancements-global-error-display').trigger('clear');
                    productBase.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
                } else {
                    $('.product-list-enhancements-global-error-display').trigger('error');
                }
                $.spinner().stop();
            },
            error: function () {
                $('.product-list-enhancements-global-error-display').trigger('error');
                $.spinner().stop();
            }
        });
    }
};

var addEventListeners = function () {
    $(window)
        .on('keydown', eventKeyPressEscapeWindow);
    $('.product-list-enhancements-detail')
        .on('updateProducts', eventUpdateProducts)
        .on('afterSelectProduct', eventUpdateSelectedProducts)
        .on('unselectAllProducts', eventUnselectAllProducts)
        .on('click', '.product-list-enhancements-detail-select-all', eventClickSelectAll)
        .on('click', '.product-list-enhancements-detail-share', eventClickShare)
        .on('click', '.product-list-enhancements-detail-add-cart', eventClickAddToCart)
        .on('click', '.product-list-enhancements-detail-deselect', eventClickDeselect)
};

var removeEventListeners = function () {
    $(window)
        .off('keydown', eventKeyPressEscapeWindow);
    $('.product-list-enhancements-detail')
        .off('updateProducts', eventUpdateProducts)
        .off('afterSelectProduct', eventUpdateSelectedProducts)
        .off('unselectAllProducts', eventUnselectAllProducts)
        .off('click', '.product-list-enhancements-detail-select-all', eventClickSelectAll)
        .off('click', '.product-list-enhancements-detail-share', eventClickShare)
        .off('click', '.product-list-enhancements-detail-add-cart', eventClickAddToCart)
        .off('click', '.product-list-enhancements-detail-deselect', eventClickDeselect)
};

var init = function () {
    if ($('.product-list-enhancements-detail').length) {
        addEventListeners();
        modalShareList.init();
        modalDeleteProducts.init();
        overlayDetailSelected.init();
        cardProduct.init();
        modalQuickView.init();
        detailNotes.init();
        errorDisplay.init();
    }
};

var destroy = function () {
    removeEventListeners();
    modalShareList.destroy();
    modalDeleteProducts.destroy();
    overlayDetailSelected.destroy();
    cardProduct.destroy();
    modalQuickView.init();
    detailNotes.destroy();
    errorDisplay.destroy();
};

module.exports = {
    init,
    destroy
};
