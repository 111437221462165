'use strict';


function createReview (nextReview, newReviewItem) {
    newReviewItem.find('.js-score').html('Score: ' + nextReview.rating + ' out of 5');
    newReviewItem.find('.js-title').html(nextReview.title || 'Customer Review:');
    newReviewItem.find('.js-body').html(nextReview.body);
    newReviewItem.find('.js-author').html(nextReview.author + ' on ' + nextReview.formattedDate);
}

module.exports = {
    methods: {
        createReview: createReview
    },
    pageChange: function() {
        $('body').on('click', '.js-static-reviews .pagination .js-page', event => {
            var $target = $(event.target);
            var state = $($target.parents('.js-static-reviews')[0]);
            var currentPage = state.data('currentpage');
            var reviewPages = state.data('reviewpages');
            var reviewsJSON = state.data('reviewsjson');
            var reviewContainer = $(state.find('.js-review-container'));
            var reviewItem = $(state.find('.js-review-container .js-review-item')[0]);

            var isNext = $target.hasClass('js-page-next');
            reviewContainer.html('');

            if (isNext && currentPage < reviewPages) {
                for (var i = currentPage * 5; i < (currentPage * 5) + 5 && i < reviewsJSON.length; i++) {
                    reviewItem.clone(true).appendTo(reviewContainer);
                }
                var node = 0;
                for (var i = currentPage * 5; i < (currentPage * 5) + 5 && i < reviewsJSON.length; i++) {
                    var nextReview = reviewsJSON[i];
                    var newReviewItem = $(state.find('.js-review-container .js-review-item')[node]);

                    module.exports.methods.createReview(nextReview, newReviewItem);
                    node++;
                }
                currentPage += 1;
            } else if (!isNext && currentPage > 1) {
                currentPage -= 1;
                for (var i = ((currentPage - 1) * 5); i < (currentPage * 5) && i < reviewsJSON.length; i++) {
                    reviewItem.clone(true).appendTo(reviewContainer);
                }
                var node = 0;
                for (var i = ((currentPage - 1) * 5); i < (currentPage * 5) && i < reviewsJSON.length; i++) {
                    var nextReview = reviewsJSON[i];
                    var newReviewItem = $(state.find('.js-review-container .js-review-item')[node]);

                    module.exports.methods.createReview(nextReview, newReviewItem);
                    node++;
                }
            }

            state.data('currentpage', currentPage);
            state.find('.js-page-previous').toggleClass('disabled', currentPage <= 1);
            state.find('.js-page-next').toggleClass('disabled', currentPage >= reviewPages);
        })
    }
}
