'use strict';

var eventClose = function(e) {
    $(this)
        .attr({
            'data-product-list-id': ''
        })
        .data({
            productListId: ''
        });
};

var eventClickDelete = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistEnhancementsModalDeleteList');
    $.spinner().start();
    $.ajax({
        url: $modal.data().url,
        method: 'POST',
        dataType: 'html',
        data: {
            productListId: $modal.data().productListId,
            paginationPage: $('.product-list-enhancements-landing-pagination').data().page
        },
        success: function(html) {
            var $html = $(html);

            $('.product-list-enhancements-global-error-display').trigger('clear');
            $('.product-list-enhancements-landing').trigger('updateLists', {
                $lists: $html.find('.lists'),
                $listsPagination: $html.find('.product-list-enhancements-landing-pagination')
            });
            $modal.modal('hide');
        },
        error: function() {
            $.spinner().stop();
            $modal.modal('hide');
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });
}

var addEventListeners = function() {
    $('#wishlistEnhancementsModalDeleteList')
        .on('click', '.product-list-enhancements-modal-delete-list-btn', eventClickDelete)
        .on('hidden.bs.modal', eventClose);
};

var removeEventListeners = function() {
    $('#wishlistEnhancementsModalDeleteList')
        .off('click', '.product-list-enhancements-modal-delete-list-btn', eventClickDelete)
        .off('hidden.bs.modal', eventClose);
};

var init = function() {
    addEventListeners();
}

var destroy = function() {
    removeEventListeners();
}

module.exports = {
    init,
    destroy
}
