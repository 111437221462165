'use strict';

const noteTextMaxLength = 256;

var closeNotes = function() {
    $('.product-list-enhancements-detail-notes-text')
        .removeClass('open');
    $('.product-list-enhancements-detail-notes-form-group')
        .attr('aria-hidden', 'true');
};

var saveNotes = function() {
    var $detailNotes = $('#wishlistEnhancementsDetailNotes');
    var $detailNotesTextarea = $detailNotes.find('.product-list-enhancements-detail-notes-text');
    var currentNoteText = $detailNotes.data('currentNoteText').trim();
    var updatedNoteText = $detailNotesTextarea.val().trim().substring(0, noteTextMaxLength);
    if (updatedNoteText !== currentNoteText) {
        $detailNotes.data('currentNoteText', updatedNoteText);
        $.ajax({
            url: $detailNotes.data().url,
            method: 'POST',
            dataType: 'json',
            data: {
                productListId: $detailNotes.data().productListId,
                productListDescription: updatedNoteText
            }
        });
    }
};

var eventKeyPressEscapeWindow = function (e) {
    if (e.key && e.key === 'Escape') {
        closeNotes();
    }
};

var eventUnloadWindow = function (e) {
    saveNotes();
};

var eventClickToggle = function(e) {
    e.preventDefault();
    var $showNotesHelperText = $('.product-list-enhancements-detail-notes-text-show');
    var $hideNotesHelperText = $('.product-list-enhancements-detail-notes-text-hide');
    var $notesText = $('.product-list-enhancements-detail-notes-text');
    if ($notesText.hasClass('open')) {
        closeNotes();
    } else {
        $notesText
            .addClass('open')
            .focus();
        $('.product-list-enhancements-detail-notes-form-group')
            .attr('aria-hidden', 'false');
    }
    $($showNotesHelperText).add($hideNotesHelperText).toggleClass('d-none');
};

var eventBlurText = function(e) {
    saveNotes();
};

var addEventListeners = function() {
    $(window)
        .on('unload', eventUnloadWindow)
        .on('keydown', eventKeyPressEscapeWindow);
    $('#wishlistEnhancementsDetailNotes')
        .on('click', '.product-list-enhancements-detail-notes-toggle', eventClickToggle)
        .on('blur', '.product-list-enhancements-detail-notes-text', eventBlurText);
};

var removeEventListeners = function() {
    $(window)
        .off('unload', eventUnloadWindow)
        .off('keydown', eventKeyPressEscapeWindow);
    $('#wishlistEnhancementsDetailNotes')
        .off('click', '.product-list-enhancements-detail-notes-toggle', eventClickToggle)
        .off('blur', '.product-list-enhancements-detail-notes-text', eventBlurText);
};

var init = function() {
    var currentNoteText = $('.product-list-enhancements-detail-notes-text').val();
    $('#wishlistEnhancementsDetailNotes').data('currentNoteText', currentNoteText);
    addEventListeners();
}

var destroy = function() {
    removeEventListeners();
}

module.exports = {
    init,
    destroy
}
