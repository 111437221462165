'use strict';

var productListEnhancementsHelpers = require('./helpers.js');

var swatchChange =function($swatch) {
    var $tileParent = $swatch.parents('.product-tile');
    //main Product List Enhancements Link
    if ($swatch.attr('data-wishlistpid')) {
        toggleButtonSelectedState($tileParent, $swatch.attr('data-wishlistpid'));
    }
};

var hijackSwatchClick = function() {
    if (window.isMobile()) {
        $('body').on('click.swatch-link', '.product-tile .swatch-link', eventHijackSwatchClick);
    } else {
        $('body').off('click.swatch-link');
    }
};

var toggleButtonSelectedState = function($container, pid) {
    //Make heart icon accurate
    var mainProductListEnhancements = $container.find('.product-list-enhancements-toggle-product');
    if (mainProductListEnhancements.length) {
        mainProductListEnhancements.attr('data-wishlistpid', pid);
        //Make heart icon accurate
        productListEnhancementsHelpers.updateLinkData(mainProductListEnhancements);
    }
};

var toggleProductGuest = function(data) {
    $.ajax({
        url: data.url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: data.wishlistpid,
            quantity: data.wishlistquantity
        },
        success: function(data) {
            if (data.success) {
                $('.product-list-enhancements-global-error-display').trigger('clear');
                productListEnhancementsHelpers.updateUncachedData({
                    actionType: data.productListActionType,
                    pid: data.pid
                });
                productListEnhancementsHelpers.updateLinkData(false, data);
                data.guest = true;
                productListEnhancementsHelpers.openToast(data);

            } else {
                $('.product-list-enhancements-global-error-display').trigger('error');
            }
        },
        error: function() {
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });
};

var eventClickHeart = function(e) {
    e.preventDefault();
    var $this = $(e.currentTarget);
    var wishlistpid = e.currentTarget.dataset.wishlistpid;
    if (window.UncachedData.customer.isAuthenticated) {
        $('#wishlistModalShowLists')
                .data('multiSelect', false)
                .data('pid', wishlistpid)
                .modal('show', $this);
    } else {
        toggleProductGuest(e.currentTarget.dataset);
    }
};

var eventMouseEnterSwatch = function() {
    swatchChange($(this));
};

var eventAfterAttributeSelect = function(e, payload) {
    toggleButtonSelectedState(payload.container, payload.data.product.wishlistpid);
};

var eventHijackSwatchClick = function(e) {
    e.preventDefault();
    swatchChange($(this).find('.swatch'));
};

var init = function() {
    $('body')
        .on('click', '.product-list-enhancements-toggle-product', eventClickHeart)
        .on('mouseenter', '.product-tile .swatch', eventMouseEnterSwatch)
        .on('product:afterAttributeSelect', eventAfterAttributeSelect);
    $(window).on('resize',function() {
        hijackSwatchClick();
    });
    hijackSwatchClick();
    productListEnhancementsHelpers.updateLinkData();
}

var destroy = function() {
    $('body')
        .off('click', '.product-list-enhancements-toggle-product', eventClickHeart)
        .off('mouseenter', '.product-tile .swatch', eventMouseEnterSwatch)
        .off('product:afterAttributeSelect', eventAfterAttributeSelect);
};

module.exports = {
    init,
    destroy
};
