'use strict';

var SiteConstants = require('constants/SiteConstants');
var updateMiniCart = true;

function setMiniCartBodyMaxHeight(minicart) {
    var $minicart = $(minicart);
    var $minicartScrollable = $minicart.find('.product-summary');
    var minicartHeight = $minicart.outerHeight();
    var minicartScrollableHeight = $minicartScrollable.outerHeight();
    var minicartNonScrollableHeight = minicartHeight - minicartScrollableHeight;
    var minicartOffset = $minicart.offset().top - $(window).scrollTop();
    var subtractHeight = minicartOffset + minicartNonScrollableHeight + SiteConstants.Spacer;

    $minicartScrollable.css('max-height', 'calc(100vh - ' + subtractHeight + 'px)');
}

module.exports = function () {
    $('.minicart').on('count:update', (event, count) => {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', () => {
        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart && !$('.minicart .popover').is(':empty')) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, data => {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
                $('body').trigger('minicart:loaded', $('.minicart .popover'));
            });
        }
    });

    $('body').on('touchstart click', event => {
        if ($('.minicart').has(event.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });

    $('.minicart').on('mouseleave focusout', event => {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
        $('body').trigger('minicart:mouseleave_focusout');
    });

    $('body').on('change', '.minicart .quantity', event => {
        if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart cart:update', () => {
        updateMiniCart = true;
    });

    $('body').on('minicart:loaded', (event, minicart) => {
        setMiniCartBodyMaxHeight(minicart);
    });
};
