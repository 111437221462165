'use strict';

var productBase = require('../product/base');

var closeOverlay = function() {
    $('.product-list-enhancements-overlay-detail-selected')
        .attr('tabindex', -1)
        .attr('aria-hidden', 'true')
        .removeClass('open');
};

var deselectAll = function() {
    $('.product-list-enhancements-detail').trigger('unselectAllProducts');
};

var getSelectedListAndProducts = function() {
    var $productListDetail = $('.product-list-enhancements-detail');
    var $products = $productListDetail.find('.product-list-enhancements-card-product[data-selected="true"]');
    var listId = $productListDetail.data().listId;
    var pids = [];
    var listItemIds = [];
    var pidsObj = [];
    $products.each(function() {
        var $product = $(this);
        pids.push($product.data().pid);
        listItemIds.push($product.data().listItemId);
        pidsObj.push({
            pid: $product.data().pid,
            qty: $product.find('.product-list-enhancements-card-product-quantity').val(),
            options: ''
        });
    });
    return {
        listId,
        listItemIds,
        pids,
        pidsObj
    };
};

var eventOpenOverlay = function() {
    $(this)
        .attr('tabindex', 0)
        .attr('aria-hidden', 'false')
        .addClass('open');
};

var eventCloseOverlay = function() {
    closeOverlay();
};

var eventClickClose = function() {
    deselectAll();
};

var eventClickAddToCart = function(e) {
    e.preventDefault();
    var selectedListAndProducts = getSelectedListAndProducts();
    var addToCartUrl = $(this).data().url;
    if (selectedListAndProducts.pidsObj.length) {
        $.spinner().start();
        selectedListAndProducts.pidsObj = JSON.stringify(selectedListAndProducts.pidsObj);
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: {
                pidsObj: selectedListAndProducts.pidsObj
            },
            success: function (data) {
                if (data.error === false) {
                    $('.product-list-enhancements-global-error-display').trigger('clear');
                    productBase.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    deselectAll();
                    productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
                } else {
                    $('.product-list-enhancements-global-error-display').trigger('error');
                }
                $.spinner().stop();
            },
            error: function () {
                $('.product-list-enhancements-global-error-display').trigger('error');
                $.spinner().stop();
            }
        });
    }
};

var eventClickShare = function(e) {
    e.preventDefault();
};

var eventClickMove = function(e) {
    e.preventDefault();
    var selectedListAndProducts = getSelectedListAndProducts();
    $('#wishlistModalShowLists')
        .data('listId', selectedListAndProducts.listId)
        .data('listItemIds', selectedListAndProducts.listItemIds)
        .data('multiSelect', false)
        .data('action', 'move')
        .modal('show');
    deselectAll();
};

var eventClickRemove = function(e) {
    e.preventDefault();
    var selectedListAndProducts = getSelectedListAndProducts();
    $('#wishlistEnhancementsModalDeleteProducts')
        .data('listId', selectedListAndProducts.listId)
        .data('listItemIds', selectedListAndProducts.listItemIds)
        .modal('show');
};

var addEventListeners = function() {
    $('.product-list-enhancements-overlay-detail-selected')
        .on('open', eventOpenOverlay)
        .on('close', eventCloseOverlay)
        .on('click', '.product-list-enhancements-overlay-detail-selected-close', eventClickClose)
        .on('click', '.product-list-enhancements-overlay-detail-selected-add', eventClickAddToCart)
        .on('click', '.product-list-enhancements-overlay-detail-selected-share', eventClickShare)
        .on('click', '.product-list-enhancements-overlay-detail-selected-move', eventClickMove)
        .on('click', '.product-list-enhancements-overlay-detail-selected-remove', eventClickRemove);
};

var removeEventListeners = function() {
    $('.product-list-enhancements-overlay-detail-selected')
        .off('open', eventOpenOverlay)
        .off('close', eventCloseOverlay)
        .off('click', '.product-list-enhancements-overlay-detail-selected-close', eventClickClose)
        .off('click', '.product-list-enhancements-overlay-detail-selected-add', eventClickAddToCart)
        .off('click', '.product-list-enhancements-overlay-detail-selected-share', eventClickShare)
        .off('click', '.product-list-enhancements-overlay-detail-selected-move', eventClickMove)
        .off('click', '.product-list-enhancements-overlay-detail-selected-remove', eventClickRemove);
};

var init = function() {
    addEventListeners();
};

var destroy = function() {
    removeEventListeners();
    closeOverlay();
};

module.exports = {
    init,
    destroy
};
