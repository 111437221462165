'use strict';

module.exports = {
    showMoreBundle: function() {
        $('body').on('click', '.js-show-more-bundle', function (event) {
            $(event.target).siblings('.more-bundle').removeClass('d-none');
            $(event.target).addClass('d-none');
            $(event.target).siblings('.js-show-less-bundle').removeClass('d-none');
        })
    },
    showLessBundle: function() {
        $('body').on('click', '.js-show-less-bundle', function (event) {
            $(event.target).siblings('.more-bundle').addClass('d-none');
            $(event.target).addClass('d-none');
            $(event.target).siblings('.js-show-more-bundle').removeClass('d-none');
        })
    }
}
