'use strict';

var updateHeader = status => {
    var $headerNav = $('.header-nav');
    var headerNavHeight = $headerNav.outerHeight();
    var $page = $('.page');

    if (status === 'fixed') {
        $headerNav.addClass('fixed');
        if (!($headerNav.closest('.transparent-header').length)) {
            $page.css('margin-top', headerNavHeight + 'px');
        }
        $headerNav.removeClass('transparent-nav');
    } else {
        $headerNav.removeClass('fixed');
        $page.css('margin-top', '');
        var isMobileOpen = $('body').hasClass('mobile-menu-in');
        if (!isMobileOpen){
            $headerNav.addClass('transparent-nav');
        }
    }
}

module.exports = function() {

    // Fix/unfix header to top of screen, dependent on class added by custom preference
    // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced. Should be replaced with CSS position:sticky once IE11 support is dropped.
    var hasFixedHeader = $('header').hasClass('fixed-header');
    $(window).scroll(event => {
        if (hasFixedHeader) {
            var $headerBanner = $('.header-banner');
            var headerBannerHeight = $headerBanner.length ? $headerBanner.outerHeight() : 0;
            var $preNavHeader = $('.pre-nav-header');
            var preNavHeaderHeight = $preNavHeader.length ? $preNavHeader.outerHeight() : 0;
            var scrollTop = $(event.target).scrollTop();

            if (scrollTop > headerBannerHeight + preNavHeaderHeight) {
                updateHeader('fixed');
            } else {
                updateHeader('unfixed');
            }
        }
    });

    var isMobile = window.isMobile();
    if ($('header').hasClass('transparent-header') && !isMobile) {
        $('.header-nav').hover(
            function() {
                $(this).removeClass('transparent-nav');
            }, function() {
                $(this).addClass('transparent-nav');
            }
        );
    }

    // Check for presence of content assets in MegaMenu
    var $megamenuDropdown = $('.megamenu > .dropdown-menu');

    $megamenuDropdown.each((index, element) => {
        var $megamenu = $(element);
        if ($megamenu.find('.megamenu-asset-1').children().length) {
            $megamenu.addClass('has-asset-1');
        }
        if ($megamenu.find('.megamenu-asset-2').children().length) {
            $megamenu.addClass('has-asset-2');
        }
    });

    // Show/hide content assets on mobile based on category level
    if (isMobile) {
        var $thirdMenuBackBtn = $('.megamenu .menu-subcategories > .dropdown-item'),
            $thirdSubLink = $('.megamenu .menu-subcategories > .dropdown-item > .dropdown-link');

        $thirdSubLink.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').addClass('thirdCatOpen');
        });
        $thirdMenuBackBtn.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').removeClass('thirdCatOpen');
        });
    }

    $('.main-menu .navbar-nav').on('mouseenter mouseleave', function () {
        $(this).toggleClass('nav-item-hover');
    });

    $('header .site-search').on('mouseenter', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        $siteSearch.addClass('expanded');
    });

    $('header .site-search').on('mouseleave', event => {
        var $siteSearch = $(event.target).closest('.site-search');
        var $input = $siteSearch.find('input');
        // Only collapse if the user isn't typing and hasn't entered text
        if (!$input.is(':focus') && !$input.val()) {
            $siteSearch.removeClass('expanded');
        }
    });

    $('form[name=simpleSearch]').on('submit', event => {
        var $form = $(event.target);
        var $input = $('input[name=q]',$form);
        //keep search from submitting if query is empty
        if ($input.val() == "") {
            $input.focus();
            event.preventDefault();
        }
    });

};
