'use strict';

const shareUrlHelpTimeout = 2000;

var eventClose = function() {
    //reset the forms
    var $shareUrl = $('#wishlistExperienceShareUrl');
    $shareUrl.val($shareUrl.data().url);
    $('#wishlistExperienceShareEmail').val('');
};

var eventSubmitSend = function(e) {
    e.preventDefault();

    var $this = $(this);
    var newLine = encodeURIComponent('\n');

    //open device email client
    window.open(
        'mailto:'
        + encodeURIComponent($('#wishlistExperienceShareEmail').val())
        + '?subject='
        + $this.data('shareEmailSubject')
        + '&body='
        + $this.data('shareEmailBodyLine1')
        + newLine
        + newLine
        + $this.data('shareEmailShareUrl1')
    );
  };

var eventSubmitCopy = function(e) {
    e.preventDefault();

    var $shareUrlHelp = $('#wishlistExperienceShareUrlHelp');
    var copyText = document.getElementById('wishlistExperienceShareUrl');

    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    $shareUrlHelp.removeClass('invisible');

    setTimeout(() => {
        $shareUrlHelp.addClass('invisible');
    }, shareUrlHelpTimeout);
};

var addEventListeners = function() {
    $('#wishlistEnhancementsModalShareList')
        .on('submit', '.send', eventSubmitSend)
        .on('submit', '.copy', eventSubmitCopy)
        .on('hidden.bs.modal', eventClose);
};

var removeEventListeners = function() {
    $('#wishlistEnhancementsModalShareList')
        .off('submit', '.send', eventSubmitSend)
        .off('submit', '.copy', eventSubmitCopy)
        .off('hidden.bs.modal', eventClose);
};

var init = function() {
    addEventListeners();
};

var destroy = function() {
    removeEventListeners();
};

module.exports = {
    init,
    destroy
};
