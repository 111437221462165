'use strict';

var modalDeleteProduct = require('./modalDeleteProduct');
var productBase = require('../product/base');

var eventSelect = function(e) {
    $(e.target)
        .attr('data-selected', true)
        .data('selected', true)
        .find('.product-list-enhancements-card-product-toggle')
        .addClass('selected');
};

var eventUnselect = function(e) {
    $(e.target)
        .attr('data-selected', false)
        .data('selected', false)
        .find('.product-list-enhancements-card-product-toggle')
        .removeClass('selected');
};

var eventUpdateQuantity = function(e, payload) {
    $(this)
        .find('.product-list-enhancements-card-product-quantity')
        .val(payload);
    $('body').trigger('productListEnhancements:afterUpdateProductQuantity');
};

var eventChangeQuantity = function(e) {
    var $this = $(this);
    var $cardProduct = $this.parents('.product-list-enhancements-card-product');
    $.ajax({
        url: $cardProduct.data().url,
        method: 'POST',
        data: {
            quantity: $this.val(),
            productListId: $cardProduct.data().listId,
            productListItemId: $cardProduct.data().listItemId
        },
        dataType: 'json',
        success: function (data) {
            if (data.success === true) {
                $('.product-list-enhancements-global-error-display').trigger('clear');
            } else {
                $('.product-list-enhancements-global-error-display').trigger('error');
            }
        },
        error: function () {
            $('.product-list-enhancements-global-error-display').trigger('error');
        }
    });
    $('body').trigger('productListEnhancements:afterUpdateProductQuantity');
};

var eventClickAddCart = function(e) {
    e.preventDefault();
    $.spinner().start();
    var $this = $(this);
    var $cardProduct = $this.parents('.product-list-enhancements-card-product');
    var addToCartUrl = $this.data().url;
    var pid = $cardProduct.data('pid');
    var quantity = $cardProduct.find('.product-list-enhancements-card-product-quantity').val();
    var options = JSON.stringify($this.data().options);
    $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: {
            pid,
            quantity,
            options
        },
        dataType: 'json',
        success: function (data) {
            if (data.error === false) {
                $('.product-list-enhancements-global-error-display').trigger('clear');
                productBase.methods.handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
            } else {
                $('.product-list-enhancements-global-error-display').trigger('error');
            }
            $.spinner().stop();
        },
        error: function () {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
};

var eventClickRemove = function(e) {
    e.preventDefault();
    $('#wishlistEnhancementsModalDeleteProduct')
        .modal('show', $(this));
};

var eventClickCheckbox = function(e) {
    e.preventDefault();
    var $this = $(this);
    var $cardProduct = $this.parents('.product-list-enhancements-card-product');
    if ($cardProduct.data().selected === true) {
        $this.removeClass('selected');
        $cardProduct
            .attr('data-selected', false)
            .data('selected', false);
    } else {
        $this.addClass('selected');
        $cardProduct
            .attr('data-selected', true)
            .data('selected', true);
    }
    $('.product-list-enhancements-detail').trigger('afterSelectProduct');
};

var addEventListeners = function() {
    $('.product-list-enhancements-card-product')
        .on('select', eventSelect)
        .on('unselect', eventUnselect)
        .on('updateQuantity', eventUpdateQuantity)
        .on('click', '.product-list-enhancements-card-product-toggle', eventClickCheckbox)
        .on('change', '.product-list-enhancements-card-product-quantity', eventChangeQuantity)
        .on('click', '.product-list-enhancements-card-product-add', eventClickAddCart)
        .on('click', '.product-list-enhancements-card-product-remove', eventClickRemove);
};

var removeEventListeners = function() {
    $('.product-list-enhancements-card-product')
        .off('select', eventSelect)
        .off('unselect', eventUnselect)
        .off('updateQuantity', eventUpdateQuantity)
        .off('click', '.product-list-enhancements-card-product-toggle', eventClickCheckbox)
        .off('change', '.product-list-enhancements-card-product-quantity', eventChangeQuantity)
        .off('click', '.product-list-enhancements-card-product-add', eventClickAddCart)
        .off('click', '.product-list-enhancements-card-product-remove', eventClickRemove);
};

var init = function() {
    addEventListeners();
    $('body').trigger('productListEnhancements:afterUpdateProductQuantity');
    modalDeleteProduct.init();
}

var destroy = function() {
    removeEventListeners();
    modalDeleteProduct.destroy();
}

module.exports = {
    init,
    destroy
}
